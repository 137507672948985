import { apiRootURL } from "./defaultSettings";
export const DOCUMENT_TITLE = "马丁集团";

export const PROXY_PORT = {
    Opel: 3001,
    "07ZR": 3002,
    Michelin: 3003,
    Ford: 3004,
    RRG: 3005,
    GtEstimate: 8889,
};

export const dockApps = [
    "citroen",
    "ds",
    "opel",
    "peugeot",
    "misterauto",
    "eurorepar",
    "actia",
];

export const iframes = {
    citroen: "https://www.citroen.fr/accueil.html",
    ds: "https://www.dsautomobiles.fr/accueil.html",
    eurorepar: "https://www.eurorepar.fr/",
    misterauto: "https://www.mister-auto.com/",
    opel: process.env.REACT_APP_PROXY_SERVER_HOST + ":" + PROXY_PORT.Opel,
    peugeot: "https://www.peugeot.fr/accueil.html",
    actia: "https://www.actia.com/fr",
    back: "https://back.ia.parts/",
    diag: "https://box.ia.parts/",
    erp: "https://office.equanim-it.fr/",
    store: "https://appstore.ia.parts",
    visio: "https://meetbox.fr/",
    com: "https://com.ia.parts/",
    help: "https://group-martin.cn",
    tecrmi: "https://rmi.ia.parts",
};

export const iframePartSelector = {
    fr: "https://group-martin.cn",
    en: "https://group-martin.cn",
};

export const iframeCarSelector = {
    fr: "https://group-martin.cn",
    en: "https://plus.ia.parts/ie.php?https://www.groupe-psa.com/en/brands-and-services/free2move/",
};

export const iframeContactSelector = {
    fr: "https://group-martin.cn",
    en: "https://group-martin.cn",
};

export const DIAG_OTHER_BRANDS = [
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
];

export const PLUS_MENU_APPS = [
    {
        id: 0,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 1,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 2,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
];

export const LEVAM_URL = "https://oem1.ia.parts/oem_martin.html";
